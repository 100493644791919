import { css } from '@emotion/core'
import React, { ReactElement } from 'react'
import { Markdown } from './Markdown'

type Props = {
  innerHTML: string
}

export function ProductDescription({ innerHTML }: Props): ReactElement {
  return (
    <Markdown
      innerHTML={innerHTML}
      className="mt-8 text-gray-700"
      css={css`
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-weight: 700;
          margin-top: 1em;
          margin-bottom: 0.5em;
        }

        ol,
        ul {
          margin-top: 1.5em;
          margin-bottom: 1.5em;
        }
      `}
    />
  )
}
