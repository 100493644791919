import { Link } from 'gatsby'
import React, { ReactElement } from 'react'
import { cx } from '../utils/helpers'
import { FeatherIcon } from './FeatherIcon'

type Props = {
  kind: 'prev' | 'next'
  image: ReactElement
  name: string
  href: string
}

export function PrevNextProduct({
  kind,
  image,
  name,
  href,
}: Props): ReactElement {
  return (
    <Link
      to={href}
      className={cx(
        'flex items-center p-4 rounded border border-gray-300 shadow-lg',
        kind === 'next' && 'flex-row-reverse',
      )}
    >
      <FeatherIcon
        name={kind === 'prev' ? 'chevron-left' : 'chevron-right'}
        size={32}
        className="flex-shrink-0 -mx-2"
      />

      <div className="mx-6 flex-shrink-0">{image}</div>
      <div className="mx-auto">{name}</div>
    </Link>
  )
}
