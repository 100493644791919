import { css } from '@emotion/core'
import React, { ReactElement } from 'react'
import { Markdown } from './Markdown'

type Props = {
  innerHTML: string
}

export function ProductSpecification({ innerHTML }: Props): ReactElement {
  return (
    <Markdown
      innerHTML={innerHTML}
      className="overflow-x-auto p-4 rounded border border-gray-300 shadow-lg bg-white"
      css={css`
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin-left: 0.5em;
          margin-bottom: 1em;
        }

        table {
          border: solid 1px var(--color-gray-300);

          &:not(:last-child) {
            margin-bottom: 2rem;
          }

          th,
          td {
            padding: 1rem;
            border: solid 1px var(--color-gray-300);
          }

          th {
            background-color: var(--color-gray-100);
          }
        }

        img {
          margin-left: auto;
          margin-right: auto;
        }

        p {
          :not(:first-child) {
            margin-top: 1rem;
          }
        }
      `}
    />
  )
}
