import { css } from '@emotion/core'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import React, { ReactElement } from 'react'
import { PrevNextProductContainerFragment } from '../../types/graphql'
import { resolvePath } from '../../utils/dato'
import { PrevNextProduct } from '../PrevNextProduct'

type Props = {
  data: {
    prevProduct: PrevNextProductContainerFragment | null
    nextProduct: PrevNextProductContainerFragment | null
  }
}

export function PrevNextProductContainer({ data }: Props): ReactElement | null {
  const { prevProduct, nextProduct } = data

  return (
    (prevProduct || nextProduct) && (
      <nav
        className="my-16 py-8 border-t border-b border-gray-300"
        css={css`
          display: grid;
          grid-gap: 2rem;
          grid-template-columns: repeat(auto-fit, minmax(12rem, 20rem));
          justify-content: center;
        `}
      >
        {prevProduct &&
        prevProduct.slug &&
        prevProduct.name &&
        prevProduct.brand &&
        prevProduct.brand.slug &&
        prevProduct.images &&
        prevProduct.images[0] ? (
          <PrevNextProduct
            kind="prev"
            image={
              prevProduct.images[0].format === 'svg' ? (
                <img
                  src={prevProduct.images[0].url!}
                  alt=""
                  style={{
                    width: 80,
                    height: 80,
                    objectFit: 'contain',
                  }}
                />
              ) : (
                <Img
                  fixed={prevProduct.images[0].fixed as FixedObject}
                  alt=""
                />
              )
            }
            name={prevProduct.name}
            href={resolvePath({
              kind: 'product',
              slug: prevProduct.slug,
              brandSlug: prevProduct.brand.slug,
            })}
          />
        ) : null}

        {nextProduct &&
        nextProduct.slug &&
        nextProduct.name &&
        nextProduct.brand &&
        nextProduct.brand.slug &&
        nextProduct.images &&
        nextProduct.images[0] ? (
          <PrevNextProduct
            kind="next"
            image={
              nextProduct.images[0].format === 'svg' ? (
                <img
                  src={nextProduct.images[0].url!}
                  alt=""
                  style={{
                    width: 80,
                    height: 80,
                    objectFit: 'contain',
                  }}
                />
              ) : (
                <Img
                  fixed={nextProduct.images[0].fixed as FixedObject}
                  alt=""
                />
              )
            }
            name={nextProduct.name}
            href={resolvePath({
              kind: 'product',
              slug: nextProduct.slug,
              brandSlug: nextProduct.brand.slug,
            })}
          />
        ) : null}
      </nav>
    )
  )
}

export const fragment = graphql`
  fragment PrevNextProductContainerFragment on DatoCmsProduct {
    name
    slug

    brand {
      slug
    }

    images {
      format
      url

      fixed(width: 80, height: 80) {
        ...DatoFixed
      }
    }
  }
`
