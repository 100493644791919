import { css } from '@emotion/core'
import { graphql } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import React, { ReactElement, useState } from 'react'
import { ProductMediaContainerFragment } from '../../types/graphql'
import { cx } from '../../utils/helpers'

type Props = {
  data: ProductMediaContainerFragment
}

export function ProductMediaContainer({ data }: Props): ReactElement {
  const { images, videos } = data
  const [activeMediaIndex, setActiveMediaIndex] = useState(0)

  if (!images || !images.length) return <p>Product must have an image.</p>

  return (
    <>
      <div>
        {/* Images */}
        {images.map((image, index) => {
          if (!image || !image.format) {
            return null
          }

          return (
            <div className={index === activeMediaIndex ? '' : 'hidden'}>
              {image.format === 'svg' ? (
                <img
                  src={image.url!}
                  alt=""
                  style={{
                    width: 612,
                    height: 612,
                    objectFit: 'contain',
                  }}
                />
              ) : (
                <Img fluid={image.fluid as FluidObject} alt="" />
              )}
              {image.title && <p>{image.title}</p>}
            </div>
          )
        })}

        {/* Videos */}
        {videos &&
          videos.length > 0 &&
          videos.map((video, index) =>
            video && video.url ? (
              <div
                className={cx(
                  'max-w-full',
                  // index continues from how many images were there
                  // e.g. if there are 4 images, index for video starts at 4
                  index + images.length === activeMediaIndex ? '' : 'hidden',
                )}
              >
                <video controls className="w-full rounded overflow-hidden">
                  <source src={video.url} />
                </video>
              </div>
            ) : null,
          )}
      </div>

      {/* Thumbnails */}
      <div
        className="mt-4"
        css={css`
          display: grid;
          grid-gap: 1rem;
          grid-template-columns: repeat(auto-fill, minmax(128px, 1fr));
        `}
      >
        {/* Thumbnails for images */}
        {images.map((image, index) =>
          image && image.thumbnail ? (
            <button
              type="button"
              className="p-2 rounded border border-gray-300 shadow-lg"
              onClick={() => setActiveMediaIndex(index)}
            >
              {image.format === 'svg' ? (
                <img
                  src={image.url!}
                  alt=""
                  style={{
                    width: 612,
                    height: 612,
                    objectFit: 'contain',
                  }}
                />
              ) : (
                <Img fluid={image.thumbnail as FluidObject} alt="" />
              )}
            </button>
          ) : null,
        )}

        {/* Thumbnails for videos */}
        {videos &&
          videos.length > 0 &&
          videos.map((video, index) =>
            video && video.url ? (
              <div
                // square aspect-ratio (consistent with image thumbnails)
                className="relative h-0 bg-black rounded shadow-lg"
                style={{
                  paddingBottom: '100%',
                }}
              >
                <button
                  type="button"
                  // index continues from how many images were there
                  // e.g. if there are 4 images, index for video starts at 4
                  onClick={() => setActiveMediaIndex(index + images.length)}
                  className="absolute w-full h-full top-0 left-0 z-1"
                />
                <video className="absolute w-full h-full top-0 left-0">
                  <source src={video.url} />
                </video>
              </div>
            ) : null,
          )}
      </div>
    </>
  )
}

export const fragment = graphql`
  fragment ProductMediaContainerFragment on DatoCmsProduct {
    images {
      format
      url
      title

      fluid(
        maxWidth: 612
        maxHeight: 612
        imgixParams: { bg: "0FFF", w: "612", h: "612", fit: "fillmax" }
      ) {
        ...DatoFluid
      }

      thumbnail: fluid(
        maxWidth: 128
        maxHeight: 128
        imgixParams: { bg: "0FFF", w: "128", h: "128", fit: "fillmax" }
      ) {
        ...DatoFluid
      }
    }

    videos {
      url
    }
  }
`
