import { css } from '@emotion/core'
import { graphql } from 'gatsby'
import React, { ReactElement } from 'react'
import { Breadcrumb, BreadcrumbItem } from '../components/Breadcrumb'
import { Catalog } from '../components/Catalog'
import { PrevNextProductContainer } from '../components/fragment-containers/PrevNextProduct.container'
import { ProductMediaContainer } from '../components/fragment-containers/ProductMedia.container'
import { ProductDescription } from '../components/ProductDescription'
import { ProductSpecification } from '../components/ProductSpecification'
import { SEO } from '../components/SEO'
import { WidthWrapper } from '../components/WidthWrapper'
import { Breadcrumb as BreadcrumbType } from '../config/gatsby-node'
import {
  ProductTemplateQuery,
  ProductTemplateQueryVariables,
} from '../types/graphql'

export type PageContext = ProductTemplateQueryVariables & {
  breadcrumbs: BreadcrumbType[]
}

type Props = {
  data: ProductTemplateQuery
  pageContext: PageContext
}

function ProductTemplate({
  data,
  pageContext: { breadcrumbs },
}: Props): ReactElement {
  const { datoCmsProduct, prevProduct, nextProduct } = data

  if (!datoCmsProduct) return <p>No product</p>

  const {
    name,
    brand,
    descriptionNode,
    images,
    videos,
    spec,
    catalogs,
  } = datoCmsProduct

  if (!name || !brand || !brand.name || !images || images.length === 0) {
    return <p>Incomplete data provided. Please check DatoCMS entry.</p>
  }

  const title = name + ' by ' + brand.name
  const metaDescription =
    name +
    ' by ' +
    brand.name +
    '. Get sales and support from PT. Unggul Semesta.'

  return (
    <>
      <SEO title={title} metaDescription={metaDescription} />

      <main>
        <Breadcrumb>
          {breadcrumbs.map(({ text, href }) => (
            <BreadcrumbItem key={href} href={href}>
              {text}
            </BreadcrumbItem>
          ))}
          <BreadcrumbItem>{name}</BreadcrumbItem>
        </Breadcrumb>

        <WidthWrapper as="section" id="overview" className="mt-16">
          <div
            css={css`
              display: grid;
              grid-gap: 4rem;
              grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
            `}
          >
            {/* Media and their thumbnails */}
            <div className="mb-8">
              <ProductMediaContainer
                data={{
                  images,
                  videos,
                }}
              />
            </div>

            <div className="my-8 pr-4">
              <h1 className="text-3xl font-bold leading-tight text-accent-800">
                {name}
              </h1>

              <p className="mt-2 text-accent-800">
                <i>by</i> {brand.name}
              </p>

              <ProductDescription
                innerHTML={descriptionNode!.childMarkdownRemark!.html!}
              />
            </div>
          </div>
        </WidthWrapper>

        <WidthWrapper as="section" id="specification" className="mt-16">
          <h2 className="text-3xl text-accent-800 mb-8 text-center font-bold">
            Specification
          </h2>

          {spec ? (
            <ProductSpecification innerHTML={spec} />
          ) : (
            <p className="mb-8 text-center text-lg text-gray-600">
              No specification available for this product
            </p>
          )}
        </WidthWrapper>

        <WidthWrapper as="section" id="catalogs" className="mt-16">
          <h2 className="text-3xl text-accent-800 mb-8 text-center font-bold">
            Catalogs
          </h2>

          {catalogs && catalogs.length > 0 ? (
            <div className="flex justify-center -m-4 flex-wrap">
              {catalogs.map(catalog => {
                if (!catalog || !catalog.url || !catalog.size) {
                  return null
                }

                return (
                  <div className="p-4 flex">
                    <Catalog
                      url={catalog.url}
                      size={catalog.size}
                      title={catalog.title || undefined}
                    />
                  </div>
                )
              })}
            </div>
          ) : (
            <p className="mb-8 text-center text-lg text-gray-600">
              No catalog available for this product
            </p>
          )}
        </WidthWrapper>

        {(prevProduct || nextProduct) && (
          <WidthWrapper as="section" id="prev-next-product">
            <PrevNextProductContainer
              data={{
                prevProduct,
                nextProduct,
              }}
            />
          </WidthWrapper>
        )}
      </main>
    </>
  )
}

export const query = graphql`
  query ProductTemplateQuery(
    $slug: String!
    $nextSlug: String
    $prevSlug: String
  ) {
    datoCmsProduct(slug: { eq: $slug }) {
      name

      brand {
        name
      }

      descriptionNode {
        childMarkdownRemark {
          html
        }
      }

      ...ProductMediaContainerFragment

      spec

      catalogs {
        title
        size
        url
      }
    }

    nextProduct: datoCmsProduct(slug: { eq: $nextSlug }) {
      ...PrevNextProductContainerFragment
    }

    prevProduct: datoCmsProduct(slug: { eq: $prevSlug }) {
      ...PrevNextProductContainerFragment
    }
  }
`

export default ProductTemplate
