import React, { ReactElement } from 'react'
import { formatBytes } from '../utils/helpers'
import { FeatherIcon } from './FeatherIcon'
import { ExternalLink } from './Links'

type Props = {
  url: string
  title?: string
  size: number
}

export function Catalog({ url, title, size }: Props): ReactElement {
  return (
    <div className="max-w-xs w-full flex flex-col rounded-lg overflow-hidden border border-gray-300 shadow-lg">
      <ExternalLink href={url} className="my-4 p-4 flex flex-col items-center">
        <img
          src={url + '?auto=format&fit=clip&w=360&h=360'}
          alt=""
          className="rounded shadow-xl"
        />
        {title && <p className="mt-4 px-4 text-center">{title}</p>}
      </ExternalLink>

      <ExternalLink
        href={url}
        className="mt-auto self-stretch flex justify-center items-center p-4 bg-accent hover:bg-brand text-white font-bold antialiased"
      >
        <FeatherIcon name="download" />
        <span className="ml-2">Download ({formatBytes(size)})</span>
      </ExternalLink>
    </div>
  )
}
